import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Inkscape har efter 13 års arbete släppt en 1.0 release candidate och vi snackar om den nya versionen av Ubuntu. Fedora i laptops, Proton Mail går full open source på rekordtid och Alex förklarar hur reproducerbara byggen fungerar i Arch. Vi har som alltid ett par goa tips till våra lyssnare och avslutar med att diskutera hur vi känner för Vim efter vår testperiod är över.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Ubuntu 20.04 släppt: `}<a parentName="li" {...{
          "href": "https://wiki.ubuntu.com/FocalFossa/ReleaseNotes"
        }}>{`https://wiki.ubuntu.com/FocalFossa/ReleaseNotes`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.phoronix.com/scan.php?page=article&item=xeonr-ubu1804-2004&num=9"
        }}>{`https://www.phoronix.com/scan.php?page=article&item=xeonr-ubu1804-2004&num=9`}</a></li>
      <li parentName="ul">{`Reproducerbara byggen i Arch:  `}<a parentName="li" {...{
          "href": "https://lists.reproducible-builds.org/pipermail/rb-general/2020-April/001905.html"
        }}>{`https://lists.reproducible-builds.org/pipermail/rb-general/2020-April/001905.html`}</a>{` `}<a parentName="li" {...{
          "href": "https://reproducible-builds.org/"
        }}>{`https://reproducible-builds.org/`}</a></li>
      <li parentName="ul">{`Fedora Laptops: `}<a parentName="li" {...{
          "href": "https://fedoramagazine.org/coming-soon-fedora-on-lenovo-laptops/"
        }}>{`https://fedoramagazine.org/coming-soon-fedora-on-lenovo-laptops/`}</a></li>
      <li parentName="ul">{`ProtonMail-appar open source: `}<a parentName="li" {...{
          "href": "https://protonmail.com/blog/android-open-source/"
        }}>{`https://protonmail.com/blog/android-open-source/`}</a></li>
      <li parentName="ul">{`Inkscape 1.0RC: `}<a parentName="li" {...{
          "href": "https://wiki.inkscape.org/wiki/index.php/Release_notes/1.0"
        }}>{`https://wiki.inkscape.org/wiki/index.php/Release_notes/1.0`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`SHARP IoT blir SLÖ pga ansiktsmasker: `}<a parentName="li" {...{
          "href": "https://www.theverge.com/2020/4/21/21229163/sharp-face-mask-demand-server-crash-iot-smart-devices"
        }}>{`https://www.theverge.com/2020/4/21/21229163/sharp-face-mask-demand-server-crash-iot-smart-devices`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Superpaper: `}<a parentName="li" {...{
          "href": "https://github.com/hhannine/Superpaper"
        }}>{`https://github.com/hhannine/Superpaper`}</a></li>
      <li parentName="ul">{`Octotree: `}<a parentName="li" {...{
          "href": "https://www.octotree.io/"
        }}>{`https://www.octotree.io/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Vim`}</h3>
    <p>{`Seb:`}</p>
    <ul>
      <li parentName="ul">{`Har stängt av pluginet`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Seb efterfrågar en grafisk Git-klient`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      